import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import './NavigationLinksContainerComponent.css';

class NavigationLinksContainerComponent extends Component {
    constructor(props){
        super(props)
        this.backgroundColors = [
            "#ED1D5C",
            "#00A3C3",
            "#F2682E",
            '#9e1fff',
            '#b452ff',
            "#633D97",
            "#4DC1B7",
        ]
    }

    renderLinkButton = (linkName, linkLocation, color, isExact=false)=>{
        if (isExact===true){

            return (
                <NavLink to={linkLocation} exact className="NavigationLink" activeClassName="linkActive">
                    <div className="animatedLinkButton">
                        <div className="animatedLinkButtonBase">{linkName}</div>
                        <div className="animatedLinkButtonHighlight" style={{backgroundColor:color}}><div className="animatedLinkButtonHighlightText" style={{backgroundColor:color}}>{linkName}</div></div>
                    </div>
                </NavLink>
            )
        }
        return (
            <NavLink to={linkLocation} className="NavigationLink" activeClassName="linkActive">
                <div className="animatedLinkButton">
                    <div className="animatedLinkButtonBase">{linkName}</div>
                    <div className="animatedLinkButtonHighlight" style={{backgroundColor:color}}><div className="animatedLinkButtonHighlightText" style={{backgroundColor:color}}>{linkName}</div></div>
                </div>
            </NavLink>
        )
    }

    render() {
        return (
            <ul className="NavigationLinkList">
                <li className="NavigationLinkListItem">
                    {this.renderLinkButton("About Me", "/", this.backgroundColors[0], true)}
                </li>
                <li className="NavigationLinkListItem">
                    {this.renderLinkButton("Blog", "/Blog", this.backgroundColors[1])}
                </li>
                <li className="NavigationLinkListItem">
                    {this.renderLinkButton("Projects", "/Projects", this.backgroundColors[2])}
                </li>
                <li className="NavigationLinkListItem">
                    {this.renderLinkButton("Contact Me", "/ContactMe", this.backgroundColors[3])}
                </li>

            </ul>
        )
    }
}

export default NavigationLinksContainerComponent