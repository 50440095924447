import React, { Component } from 'react'
import LoadingIconComponent from '../LoadingIconComponent'
import './ArticlePage.css';
import { connect } from 'react-redux'
import {fetchArticleData, createRemoveArticleDataAction} from '../../Data/ArticleData'
import ArticleBanner from './ArticleBanner'
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atelierDuneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ArticleNavigationComponent from './ArticleNavigationComponent'
import { NavLink, withRouter } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage'
import ApiErrorPage from '../ApiErrorPage'


class ArticlePage extends Component {
    componentDidMount() {
        const {articleData} = this.props;
        const {articleId} = this.props.match.params;
        if (!(articleId in articleData.loadedArticles)){
            this.props.dispatch(fetchArticleData(articleId));
        }
        document.title = articleId
    }
    componentWillUnmount(){
        const {articleId} = this.props.match.params;
        this.props.dispatch(createRemoveArticleDataAction (articleId))
    }


    render() {
        const {articleId} = this.props.match.params;
        const {articleData} = this.props;

        if (articleId in articleData.loadedArticles){
            if ("apiError" in this.props.articleData.loadedArticles[articleId] &&
                this.props.articleData.loadedArticles[articleId]['apiError'] === true)
            {
                return this.renderCouldNotConnect();
            }
            if ("articleExists" in this.props.articleData.loadedArticles[articleId] &&
                this.props.articleData.loadedArticles[articleId]['articleExists'] !== true){
                return this.renderNotFound();
            }
            return this.renderArticle();
        }
        else{
            return this.renderLoading()
        }
    }

    renderCouldNotConnect(){
        document.title = "Error!"
        return (<ApiErrorPage />);
    }

    renderNotFound(){
        document.title = "Page Not Found"
        return (<NotFoundPage/>)
    }

    renderLoading(){
        return (
            <div className="ArticleMain">
                <ArticleBanner mainTitle="Blog Article" subTitle="Now Loading..." />
                <div className="loadingIconContainer">
                    <LoadingIconComponent />
                </div>
            </div>
        )
    }

    renderArticle(){
        const {articleId} = this.props.match.params;
        let articleData = this.props.articleData.loadedArticles[articleId];
        const {publishDay, publishMonth, publishYear, title, articleContent} = articleData;
        let formattedDateString = "Published: "+publishYear+", " +publishMonth+", "+publishDay
        let anchors = this.getAllAnchors()
        document.title = title

        return (
            <div className="ArticleMain">
                <ArticleBanner mainTitle={title} subTitle={formattedDateString} />

                {this.renderBackToBlogButton()}

                <div className = "ArticleDataContainer">


                    {this.renderArticleContent(articleContent)}

                    {anchors.length !== 0 &&(
                            <div className="ArticleNavigationContainer">
                                <ArticleNavigationComponent anchors={anchors} />
                            </div>)
                    }

                </div>


            </div>
        )
    }

    renderBackToBlogButton(){
        var {pathname} = this.props.location;
        if (pathname){
            const page_name = pathname.split("/")[1]
            pathname = page_name
        }
        return(<div className = "BackToBlogPageButtonContainer">
                    <NavLink to={"/"+pathname} exact className="BackToBlogPageButton">
                        <div className="BackToBlogPageText">Back To {pathname} Page</div>
                    </NavLink>
                </div>)
    }

    getAllAnchors(){
        const {articleId} = this.props.match.params;
        let articleData = this.props.articleData.loadedArticles[articleId];
        const {articleContent} = articleData;
        let anchors = articleContent.filter(value => value.content_type ==="anchor")
        anchors = anchors.map((value, index) => {return value.content})
        return anchors;
    }

    renderArticleContent(data){
        return (
            <div className="ArticleContentContainer">
                {data.map((value, index) =>{

                    let {content_type, content} = value;

                    switch(content_type){
                        case "paragraph":
                            return this.renderParagraph(content, index)
                        case "youtube_video":
                            return this.renderYoutubeVideo(content, index)
                        case "main_header":
                            return this.renderMainHeader(content, index)
                        case"sub_header":
                            return this.renderSubheader(content, index)
                        case "anchor":
                            return this.renderAnchorPoint(content, index)
                        case"full_image":
                            return this.renderFullImage(content, index)
                        case"left_float_image":
                            return this.renderLeftFloatImage(content, index)
                        case"right_float_image":
                            return this.renderRightFloatImage(content, index)
                        case"code_block":
                            return this.renderCodeBlock(content, index)
                        case"numbered_list":
                            return this.renderNumberedList(content, index)
                        case"unordered_list":
                            return this.renderBulletList(content, index)
                        default:
                            return (<div key={"articleElement"+index}>Article Content not recognized....{content_type}</div>)
                    }
                })}
            </div>
        )
    }

    renderAnchorPoint(anchor, index){
        return (<a key={"article_element"+index} id={anchor.anchorId} name={anchor.anchorId}></a>)
    }

    renderFullImage(imageContent, index){
        var className = "";
        if ("class" in imageContent){
            className = imageContent['class']
            var i = 1/0;
        }
        console.log(imageContent)

        const {imageUrl, comment} = imageContent;
        if (comment == null){
            return ( <img key={"article_element"+index}  alt="FullSizeImage" className={"FullSizeImage "+className} src={imageUrl} />)
        }
        return (
            <div key={"article_element"+index}  className="FullSizeImage">
                <img alt="FullSizeImage" className={"BlogImage "+className} src={imageUrl} />
                <div>{comment}</div>
            </div>
        )
    }
    renderLeftFloatImage(imageContent, index){
        const {imageUrl, comment} = imageContent;
        if (comment == null){
            return ( <img key={"article_element"+index}  alt="LeftFloatImage" className="LeftFloatImage" src={imageUrl} />)
        }
        return (
            <div key={"article_element"+index}  className="LeftFloatImage">
                <img alt="LeftFloatImage" className="BlogImage" src={imageUrl} />
                <div>{comment}</div>
            </div>
        )

    }
    renderRightFloatImage(imageContent, index){
        const {imageUrl, comment} = imageContent;
        if (comment == null){
            return ( <img key={"article_element"+index}  alt="RightFloatImage" className="RightFloatImage" src={imageUrl} />)
        }
        return (
            <div key={"article_element"+index}  className="RightFloatImage">
                <img alt="RightFloatImage" className="BlogImage" src={imageUrl} />
                <div>{comment}</div>
            </div>
        )

    }
    renderYoutubeVideo(videoUrl, index){
        return (
            <div key={"article_element"+index}  className="youtubeVideoWrapper">
                <iframe id={"youtubeVideo-"+videoUrl} title={"youtubeVideo-"+videoUrl} className="youtubeVideoContainer" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        )
    }


    renderParagraph(paragraphContent, paragraph_index){
        return(
            <div key={"article_element"+paragraph_index}  className="paragraph">
                {paragraphContent.map((value, index) =>{
                    const {textType, text} = value;
                    switch(textType){
                        case"plain":
                            return (<span key={"Paragraph-"+paragraph_index+index+textType} className="PlainText">{text} </span>)
                        case"bold":
                            return (<span key={"Paragraph-"+paragraph_index+index+textType} className="BoldText">{text} </span>)
                        case"link":
                            return (<a key={"Paragraph-"+paragraph_index+index+textType} className="" href={text}>Here</a>)
                        default:
                            return (<span key={"Paragraph-"+paragraph_index+index+textType} >Content type not recognized </span>);
                    }
                })}
            </div>
        )

    }

    renderMainHeader(headerText, index){
        return (<h1 key={"article_element"+index}  className="HeaderText">{headerText}</h1>)
    }
    renderSubheader(headerText, index){
        return (<h2 key={"article_element"+index}  className="SubHeaderText">{headerText}</h2>)
    }
    renderNumberedList(listContents, list_index){
        return(
            <ol key={"article_element"+list_index}  className="ArticleList">
                {listContents.map((value, index) =>{
                    return (<li key={"list"+list_index+"-element-"+index}className="ListText">{value}</li>)
                })}
            </ol>
        )
    }
    renderBulletList(listContents, list_index){

        return(
            <ul key={"article_element"+list_index}  className="ArticleList">
                {listContents.map((value, index) =>{
                    return (<li key={"list"+list_index+"-element-"+index} className="ListText">{value}</li>)
                })}
            </ul>
        )
    }
    renderCodeBlock(codeContent, index){
        const {codeString, title, language} = codeContent;
        return(
            <div className="CodeBlockWrapper">
                <div key={"article_element"+index}  className="CodeBlock">
                    <div className="CodeBlockTitle">{title}</div>
                    <SyntaxHighlighter language={language} style={atelierDuneDark}>
                        {codeString}
                    </SyntaxHighlighter>
                </div>
            </div>
        )
    }
}


function mapStateToProps ({ articleData }) {
  return {
    articleData
  }
}

export default withRouter(connect(mapStateToProps)(ArticlePage));