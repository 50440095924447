import React, { Component } from 'react'
import './SocialMediaIconContainerComponent.css';

class SocialMediaIconContainerComponent extends Component {
    render() {
        return (
           <div className="SocialMediaContainer">
                <a className="SocialMediaIcon" href="https://github.com/tpoff">
                    <img alt="GithubLogo" className="SocialMediaIconGray" src="/social_media_icons/logo-github.svg" />
                    <img alt="GithubLogo" className="SocialMediaIconColor"  src="/social_media_icons/logo-github.png" />
                </a>
                <a className="SocialMediaIcon" href="https://www.youtube.com/channel/UCo35Tuuca3zaPX5ZolGP6Ow">
                    <img alt="youtube logo" className="SocialMediaIconGray" src="/social_media_icons/logo-youtube.svg" />
                    <img alt="youtube logo" className="SocialMediaIconColor"  src="/social_media_icons/logo-youtube.png" />
                </a>
                <a className="SocialMediaIcon" href="https://twitter.com/TPoff">
                    <img alt="twitter logo" className="SocialMediaIconGray" src="/social_media_icons/logo-twitter.svg" />
                    <img alt="twitter logo" className="SocialMediaIconColor"  src="/social_media_icons/logo-twitter.png" />
                </a>
                <a className="SocialMediaIcon" href="https://www.linkedin.com/in/tylerpoff/">
                    <img alt="linkedin logo" className="SocialMediaIconGray" src="/social_media_icons/logo-linkedin.svg" />
                    <img alt="linkedin logo" className="SocialMediaIconColor"  src="/social_media_icons/logo-linkedin.png" />
                </a>

           </div>
        )
    }
}

export default SocialMediaIconContainerComponent