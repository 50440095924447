import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './ArticleNavigationComponentStyle.css'

/*thoughts on animation
without getting too crazy (this might allready be crazy)
black text (or gray) on white, colored line seperators,
on hover chang text to bright color, dark highlight, edge border color as well
colored article selector highlights where on the page you are. animated on transition,

colored border? looks kinda plain as is at the moment. */

class ArticleNavigationComponent extends Component {
    constructor(props){
        super(props)
        this.state = {"activeAnchorIndex": 0, arrowOffset: 50}
        this.linkColors = [
            'ArticleAnchorLinkTextPink',
            'ArticleAnchorLinkTextTeal',
            'ArticleAnchorLinkTextOrange',
            'ArticleAnchorLinkTextPurple',
        ]
    }
    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll =()=> {

        let anchors = this.props.anchors
        let windowTopLocation = window.pageYOffset
        let windowHeight = window.innerHeight
        let anchorCutoff = windowTopLocation + windowHeight*.25;
        let anchorIndex = 0
        for (let i=1;i<anchors.length; i++){
            let anchor = anchors[i];
            let element = document.getElementById(anchor.anchorId)
            let elementTopLocation = element.offsetTop;
            if (anchorCutoff < elementTopLocation){
                break;
            }
            anchorIndex += 1;
        }
        if (anchorIndex !== this.state.currentAnchorIndex){
            this.setState({"activeAnchorIndex":anchorIndex});
            this.calculateArrowOffset();
        }
    }


    getArrowOffset(){
        let activeAnchorIndex = this.state.activeAnchorIndex;
        let anchorLinkElementId = "ArticleQuickLink-"+activeAnchorIndex.toString()
        let anchorLinkElement = document.getElementById(anchorLinkElementId)
        if (anchorLinkElement === null){
            return 50;
        }
        return (anchorLinkElement.offsetTop + anchorLinkElement.offsetTop+anchorLinkElement.offsetHeight-20)/2
    }

    calculateArrowOffset(){
        let currentArrowOffset = this.state.arrowOffset;
        let proposedArrowOffset = this.getArrowOffset();
        if (currentArrowOffset !== proposedArrowOffset){
            this.setState({arrowOffset: proposedArrowOffset})
        }
    }

    render() {
        let arrow_distance = this.state.arrowOffset;
        let arrow_distance_str = arrow_distance.toString()+"px";
        let anchors = this.props.anchors
        return (
            <div id="ArticleNavigationBase" className="ArticleNavigationBase">
                <div className="ArticleNavigationIndicatorArrow" style={{top:arrow_distance_str}}/>
                <div className="NavLinksTopText">Quick Links </div>
                <div className = "NavLinkTopTextSeperator" />
                <ul className="ArticleAnchorList">
                    {anchors.map((value, index) =>{
                        return (<li
                        key={"ArticleQuickLink-"+index.toString()}
                         id={"ArticleQuickLink-"+index.toString()}><AnchorLink
                         href={('#'+value.anchorId)}
                         className={`ArticleAnchorLink ${index===this.state.activeAnchorIndex ? "ArticleLinkActive" : ""}`}>
                            <div className={("ArticleAnchorLinkText "+this.linkColors[index%this.linkColors.length])}>{value.anchorText}</div>
                        </AnchorLink></li>)
                    })}
                </ul>
            </div>
        )
    }
}

export default ArticleNavigationComponent