import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import './BlogCard.css';

class BlogCard extends Component {
    render() {
        const {publishDay, publishMonth, publishYear, title, blurb, blogImage, articleId} = this.props;
        const blogArticleLink = "/Blog/"+articleId
        return (
            <div className="BlogCardContainer">
                <div className="DateBox">
                    <div className="DateDay">{publishDay}</div>
                    <div className="DateMonthYear">{publishMonth}, {publishYear}</div>
                </div>
                <div>
                    <div className="BlogArticleName">{title}</div>
                    <img alt="BlogCardImage" className="BlogCardImage" src={blogImage} />
                    <div className="BlogBlurb">
                        {blurb}
                    </div>
                    <NavLink to={blogArticleLink} className="ReadMoreWrapper"><span className="ReadMorebutton">Read Article</span></NavLink>

                </div>
            </div>
        )
    }
}

export default BlogCard