import React, { Component } from 'react'
import "./IntroComponentStyles.css"


class IntroComponent extends Component {
    render() {
        return (
            <div className="IntroductionComponentContainer">
                <img alt="getting rid of warnings..." className="IntroFog" src="/intro_banner.png" />

                <img alt="getting rid of warnings..." className="IntroPortrait" src="/portraits/dot_thing_v2.png" />
                <div className="IntroTextContainer">
                    <div className="IntroTextLine1">Hello, I'm</div>
                    <div className="IntroTextLine2">Tyler Poff</div>
                    <div className="IntroTextLine3">Programmer/Developer</div>
                </div>
            </div>
        )
    }
}

export default IntroComponent