import React, { Component } from 'react'
import BlogBanner from './BlogBanner'
import LoadingIconComponent from '../LoadingIconComponent'
import './BlogPage.css';
import BlogCardListContainer from "./BlogCardListContainer"
import {fetchNextBlogBlurbBatch, createClearBlogBlurbsAction} from '../../Data/BlogBlurbData'
import { connect } from 'react-redux'

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lengthOfBlogs: 0,
            loadingMoreBlogs: false
        };
    }
    componentWillReceiveProps(nextProps){
        const {blogBlurbs} = nextProps.blogBlurbs;
        if (blogBlurbs.length !== this.state.lengthOfBlogs){
            this.setState({
                lengthOfBlogs: blogBlurbs.length,
                loadingMoreBlogs: false
            })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = "Tyler Poff Blog"
    }
    componentWillUnmount(){
        this.props.dispatch(createClearBlogBlurbsAction ())
    }
    handleLoadMoreClick(){
        let l = this.props.blogBlurbs.blogBlurbs.length;
        this.props.dispatch(fetchNextBlogBlurbBatch(l))
        this.setState({
            loadingMoreBlogs: true
        })
    }


    render() {
        const {blogBlurbs, endOfData} = this.props.blogBlurbs;
        if (blogBlurbs.length === 0 && endOfData===false){
            return this.renderLoadingInitial()
        }
        else{
            return this.renderBlogData()
        }
    }
    renderLoadingInitial(){
        return (
            <div className="BlogPageWrapper">
                <BlogBanner />
                {this.renderLoadingIcon()}
            </div>
            )
    }
    renderLoadingIcon(){
        return (
            <div>

                <h3>Loading Blog Data</h3>
                <div className="loadingIconContainer">
                    <LoadingIconComponent />
                </div>
            </div>
        )
    }
    renderBlogData(){

        const {blogBlurbs} = this.props.blogBlurbs;
        return (
            <div className="BlogPageWrapper">
                <BlogBanner />

                <div className="BlogContentArea">
                    <div className="flexBox">
                        <div className="BlogBlurbArea">
                            <BlogCardListContainer blogBlurbs={blogBlurbs}/>
                            {
                                this.renderMoreBlogSection()
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderMoreBlogSection(){
        const { endOfData, apiError} = this.props.blogBlurbs;
        if (apiError === true){
            return (<div className="ContactMeFormErrorText"><span>Error!</span> Could not load blog data... please try again later</div>)
        }
        if (this.state.loadingMoreBlogs === true){
            return this.renderLoadingIcon();
        }
        if (endOfData === false){
            return (<button className="LoadMoreBlogsButton" onClick={()=>{this.handleLoadMoreClick()}}>Load More Blogs</button>)
        }
        return (<div></div>)
    }
}


function mapStateToProps ({ blogBlurbs }) {
  return {
    blogBlurbs
  }
}

export default connect(mapStateToProps)(BlogPage);