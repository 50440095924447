import React, { Component } from 'react'
import "./AboutMeSummaryComponentStyles.css"
import AboutMeBackgroundComponent from './AboutMeBackground'

class AboutMeSummaryComponent extends Component {
    render() {
        return (
            <div className="AboutMeSummaryComponentContainer">

                <div className="AboutMeBackgroundContainer">
                    <AboutMeBackgroundComponent />
                </div>

                <div className="AboutMeComponentTitle"><div className="SkillsTitlePinkLine" />About Me</div>
                <div className="AboutMeSummaryText">
                    <div className="slightBottomMargin">
                        Hi, I'm Tyler Poff!
                    </div>
                    <div>
                        I'm  Programmer and Developer on a mission to create and invent things to help and entertain people. If I can't make the world a better place I will at least try to take your mind off of it!
                    </div>
                    <ul className="AboutMeStats">
                        <li>
                            <div className="AboutMeSummaryLi">Name: </div>Tyler Poff
                        </li>
                        <li>
                            <div className="AboutMeSummaryLi">Works At: </div>AT&T
                        </li>
                        <li>
                            <div className="AboutMeSummaryLi">Career: </div>Software Developer
                        </li>
                        <li>
                            <div className="AboutMeSummaryLi">Life Goal: </div>Supreme Overlord
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default AboutMeSummaryComponent