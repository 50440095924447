import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css';
import NavigationComponent from './components/NavigationComponents/NavigationComponent'
import BlogPage from './components/BlogPage/BlogPage'
import ArticlePage from './components/ArticlePage/ArticlePage'
import ProjectPage from './components/ProjectPage/ProjectPage'
import AboutMePage from './components/AboutMeComponents/AboutMePage'
import ResumePageComponent from './components/ResumePage/ResumePageComponent'
import NotFoundPage from './components/NotFoundPage'
import ContactMePageComponent from './components/ContactMePage/ContactMePageComponent'
import { connect } from 'react-redux'
import {loadInitialData, loadProjectBlurbData} from "./Data"
import FooterComponent from './components/FooterComponent'

class App extends Component {
  componentDidMount() {
    this.props.dispatch(loadInitialData())
    this.props.dispatch(loadProjectBlurbData())
  }
  render(){
      return (
        <Router>
            <div className="App">
                <Fragment>
                    <NavigationComponent/>
                    <div  className="page_content">
                        <Switch>
                            <Route path='/' exact component={AboutMePage} />
                            <Route path='/Blog/:articleId' component={ArticlePage} />
                            <Route path='/Blog' component={BlogPage} />
                            <Route path ='/AboutMe' component={AboutMePage} />
                            <Route path='/Projects/:articleId' component={ArticlePage} />
                            <Route path='/Projects' component={ProjectPage} />
                            <Route path='/Resume' component={ResumePageComponent} />
                            <Route path='/ContactMe' component={ContactMePageComponent} />
                            <Route path='/*' component={NotFoundPage} />
                        </Switch>
                        <FooterComponent />
                    </div>

                </Fragment>
            </div>
        </Router>
      );
  }
}

function mapStateToProps () {
  return {

  }
}

export default connect(mapStateToProps)(App);
