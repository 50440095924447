import React, { Component } from 'react'
import "./RecentBlogComponentStyles.css"
import BlogCard from '../BlogPage/BlogCard'
import { connect } from 'react-redux'
import LoadingIconComponent from '../LoadingIconComponent'


class RecentBlogComponent extends Component {
    render() {

        return (
            <div className="RecentBlogComponentContainer">
                <div className="RecentBlogComponentTitle"><div className="RecentBlogLine" />Recent Blog Posts</div>
                {this.renderBlogCard()}

            </div>
        )
    }
    renderBlogCard(){
        const {blogBlurbs, apiError} = this.props.blogBlurbs;
        let recentBlog = blogBlurbs[0];
        if (apiError === true){
            return (<div className="RecentBlogError"><span>Error!</span> Could not load blog data... please try again later</div>)
        }
        if (!recentBlog){
            return (
                <div className="RecentBlogLoadingContainer">
                    <div className="LoadingRecentText">Loading Recent Blogs</div>
                    <div className="LoadingRecentIcon"><LoadingIconComponent /></div>
                </div>
            )
        }
        let {publishDay, publishMonth, publishYear, title, blurb, blogImage, articleId} = recentBlog;
        return (<BlogCard
                        key="recentBlog"
                        publishDay={publishDay}
                        publishMonth={publishMonth}
                        publishYear={publishYear}
                        title={title}
                        blurb={blurb}
                        blogImage={blogImage}
                        articleId={articleId}
                    />)
    }
}


function mapStateToProps ({ blogBlurbs }) {
  return {
    blogBlurbs
  }
}

export default connect(mapStateToProps)(RecentBlogComponent);