import React, { Component } from 'react'
import BlogCard from './BlogCard'

class BlogCardListContainer extends Component {
    render() {
        const {blogBlurbs} = this.props;

        return (
            <div className="BlogCardListContainer">
                {blogBlurbs.map((value, index)=>{
                    let {publishDay, publishMonth, publishYear, title, blurb, blogImage, articleId} = value;
                    return <BlogCard
                        key={index}
                        publishDay={publishDay}
                        publishMonth={publishMonth}
                        publishYear={publishYear}
                        title={title}
                        blurb={blurb}
                        blogImage={blogImage}
                        articleId={articleId}
                    />
                })}
            </div>
        )
    }
}

export default BlogCardListContainer