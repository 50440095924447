import React, { Component } from 'react'
import './ApiErrorPageStyles.css';

class ApiErrorPage extends Component {
    render() {
        return (
            <div className="ErrorPageContainer">
                <h1>ERROR</h1>
                <h2>Couldn't connect to server...</h2>
                <h2>Please try again later</h2>
            </div>
        )
    }
}

export default ApiErrorPage