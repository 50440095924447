import React, { Component } from 'react'
import "./StarBackgroundComponentStyles.css"


class StarBackgroundComponent extends Component {
    render() {
        return (
            <div className="StarBackgroundContainer">
                star background component!
                <div id="background"></div>
                <div id="midground"></div>
                <div id="foreground"></div>
            </div>
        )
    }
}

export default StarBackgroundComponent