import React, { Component } from 'react'
import "./AboutMePageStyles.css"
import IntroComponent from "./IntroComponent"
import SkillsComponent from "./SkillsComponent"
import EducationComponent from './EducationComponent'
import RecentBlogComponent from "./RecentBlogComponent"
import EmploymentHistoryComponent from './EmploymentHistoryComponent'
import AboutMeSummaryComponent from './AboutMeSummaryComponent'
import ProjectHighlightComponent from './ProjectHighlightComponent'
import ContactMeComponent from './ContactMeComponent'

class AboutMePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = "Tyler Poff Home"
    }
    render() {
        return (
            <div className="AboutMeContent">
                <IntroComponent />

                <AboutMeSummaryComponent />

                <div className="PinkDivider" />
                <RecentBlogComponent />

                <div className="PurpleDivider" />
                <ProjectHighlightComponent />

                <div className="TealDivider" />
                <SkillsComponent />

                <div className="OrangeDivider" />
                <EducationComponent />

                <div className="PinkDivider" />
                <EmploymentHistoryComponent />
                <div className="TealDivider" />
                <ContactMeComponent />
            </div>
        )
    }
}

export default AboutMePage