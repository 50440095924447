import React, { Component } from 'react'
import './ProjectHighlightComponentStyles.css';
import { connect } from 'react-redux'
import LoadingIconComponent from '../LoadingIconComponent'
import ProjectCategorySliderComponent from '../ProjectPage/ProjectCategorySliderComponent'

class ProjectHighlightComponent extends Component {
    renderLoadingIcon(){
        return (
            <div>

                <h3>Loading Project Data</h3>
                <div className="loadingIconContainer">
                    <LoadingIconComponent />
                </div>
            </div>
        )
    }
    renderError(){
        return (
            <div className="ProjectPageErrorWrapper">
                <div className="ContactMeFormErrorText"><span>Error!</span> Could not load blog data... please try again later</div>
            </div>
        )
    }
    renderProject(){
        let highlighted_projects = this.props.projectBlurbs.projectBlurbs[0]
        return (<ProjectCategorySliderComponent project_category={highlighted_projects} fade_index={0}/>)
    }
    renderMainContent(){
        if (this.props.projectBlurbs.apiError === true){
            return this.renderError()
        }
        if (this.props.projectBlurbs.projectBlurbs.length === 0){
            return this.renderLoadingIcon();
        }
        return this.renderProject()

    }
    render() {
        return (
            <div className="ProjectHighlightComponentContainer">
                <div className="ProjectComponentTitle"><div className="ProjectComponentsTitleLine" />Projects</div>
                {this.renderMainContent()}
            </div>
        )
    }
}


function mapStateToProps ({ projectBlurbs }) {
  return {
    projectBlurbs
  }
}

export default connect(mapStateToProps)(ProjectHighlightComponent);