import React, { Component } from 'react'
import './NotFoundPageStyles.css';

class NotFoundPage extends Component {
    render() {
        let message = "";
        if (this.props.errorMessage){
            message = this.props.errorMessage
            console.log(this.props.errorMessage)
        }
        return (
            <div className="NotFoundPageContainer">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <h3>{message}</h3>
            </div>
        )
    }
}

export default NotFoundPage