import React, { Component } from 'react'
import './BurgerIconButton.css';
import { withRouter } from 'react-router-dom';

class BurgerIconButton extends Component {
    constructor(props){
        super(props)
        this.state = {"pressed":false}
        this.callbackFunction = this.props.callbackFunction
    }
    componentWillMount(){
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({"pressed": false})
            this.callbackFunction(false)
        });
    }
    componentWillUnmount(){
        this.unlisten()
    }
    toggleButton = ()=>{
        this.setState({"pressed": !this.props.burgerMenuOpen})
        this.callbackFunction(!this.props.burgerMenuOpen)
    }
    render() {
        return (
            <div>
                <div className={"burgerContainer "+(this.props.burgerMenuOpen ? "opened" : "")} onClick={this.toggleButton}>
                    <div className={"bar1 "+(this.props.burgerMenuOpen ? "opened" : "")}></div>
                    <div className={"bar2 "+(this.props.burgerMenuOpen ? "opened" : "")}></div>
                    <div className={"bar3 "+(this.props.burgerMenuOpen ? "opened" : "")}></div>
                </div>
            </div>

        )
    }
}

export default withRouter(BurgerIconButton)