import React, { Component } from 'react'
import './ResumePageStyle.css';

class ResumePageComponent extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = "Tyler Poff Resume"
    }
    componentWillUnmount(){
    }


    render() {
        return (
            <div className="ResumePageContent">
                <h1 className="ProjectCategoryHeaderText">Resume</h1><div className="ProjectCategoryDividerLine"><hr /></div>
                <embed className="ResumePdfContainer" src="/resume/tpoff_resume.pdf" width="100%" height="100%" type="application/pdf" />
            </div>)
    }


}

export default ResumePageComponent;