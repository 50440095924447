import {mockBlogBlurbs} from "./_DATA_MOCK"
import {blog_blurb_api, api_url} from './DataConfig'
const ADD_BLOG_BLURBS = "ADD_BLOG_BLURBS"
const CLEAR_EXCESS_BLOG_BLURB_DATA = "CLEAR_EXCESS_BLOG_BLURB_DATA"
const BLOG_GROUP_SIZE = 5

// creates the action item that feeds into redux to add blog blurbs
function createAddBlogBlurbsAction (blogBlurbs) {
    return {
        type: ADD_BLOG_BLURBS,
        blogBlurbs,
    }
}
export function createClearBlogBlurbsAction () {
    return {
        type: CLEAR_EXCESS_BLOG_BLURB_DATA,
    }
}


// the then gets used as the resolution function, so whatever gets passed into resolutionFunction
// is used in the then argument, so for the promise.all it gets a list, since each resolution is passing in
// it's own item, 1 element for each promise.
export function fetchMockBlogBlurbData(starting_index, num_blogs){
    return fetch('http://jsonplaceholder.typicode.com/users')
        .then(res=>res.json())
        .then((data)=>{
            data = mockBlogBlurbs["data"];
            let l = data.length;
            let adjustedStartingIndex = Math.min(starting_index, l);
            let adjustedEndIndex = Math.min(adjustedStartingIndex + num_blogs, l);
            data = data.slice(adjustedStartingIndex, adjustedEndIndex);
            let endOfData = adjustedEndIndex === l;
            data = {
                blogBlurbs: data,
                endOfData: endOfData
            }
            return data
        })
}
export function fetchBlogBlurbDataFromApi(starting_index, num_blogs){
    let url = api_url+blog_blurb_api+"/"+starting_index+"/"+num_blogs;
    let sentData={
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':api_url+'*'
      }
    };
    return fetch(url, sentData)
        .then(res=>res.json())
        .then((data)=>{
            return data
        }).catch((error) => {
          return {
            apiError:true,
            blogBlurbs:[],
            endOfData:true,
          }
        })

}

export function fetchBlogBlurbData(starting_index, num_blogs){
    if (api_url!==""){
        return fetchBlogBlurbDataFromApi(starting_index, num_blogs);
    }
    return fetchMockBlogBlurbData(starting_index, num_blogs)
}

export function fetchNextBlogBlurbBatch(starting_index){
    return (dispatch) => {
        fetchBlogBlurbData(starting_index, BLOG_GROUP_SIZE)
        .then(data=>createAddBlogBlurbsAction(data))
        .then((data)=>{
            dispatch(data)
        })
    }
}

export function fetchInitialBlogBlurbData(){
    return fetchNextBlogBlurbBatch(0)
}


export function blogBlurbs(state = {blogBlurbs: [], endOfData:false}, action){
  switch(action.type) {
    case ADD_BLOG_BLURBS :
        return {
            ...state,
            endOfData: action.blogBlurbs['endOfData'],
            apiError: action.blogBlurbs.apiError,
            blogBlurbs: [
                ...state.blogBlurbs,
                ...action.blogBlurbs["blogBlurbs"]
            ]
        };
    case CLEAR_EXCESS_BLOG_BLURB_DATA:
        let newEndOfData = state.endOfData;
        let newBlogBlurbs = state.blogBlurbs.slice(0, BLOG_GROUP_SIZE)
        if (newBlogBlurbs.length !== state.blogBlurbs.length){
            newEndOfData = false;
        }
        return {
            ...state,
            blogBlurbs: newBlogBlurbs,
            endOfData: newEndOfData
        }
    default :
      return state
  }
}