import React, { Component } from 'react'
import "./SkillsComponentStyles.css"


class SkillsComponent extends Component {


    render() {
        let data = [
            {icon_source:"/skills_icons/development_icon1.png", title:"Software Dev", text:"I have a passion for developing new tech with whatever tools I can!"},
            {icon_source:"/skills_icons/python_black_outline.png", title:"Python", text:"With 3 years experience, Python is my go to language!"},
            {icon_source:"/skills_icons/robot_head.png", title:"AI", text:"Building bots to help us build the future!"},
            {icon_source:"/skills_icons/react_logo.png", title:"React", text:"Building websites with the #1 framework!"},

            {icon_source:"/skills_icons/blender_icon.png", title:"Blender", text:"CGI artist in the making!"},
            {icon_source:"/skills_icons/unreal_logo.png", title:"Unreal Engine 4", text:"Building new worlds and experiences!"},
            {icon_source:"/skills_icons/c-plus-plus-logo.png", title:"C++", text:"Building programs with a bit more kick!"},
            {icon_source:"/skills_icons/docker_logo.png", title:"Docker", text:"Creating deployment ready applications!"}
        ]
        return (
            <div className="SkillsComponentContainer">
                <div className="SkillsComponentTitle"><div className="SkillsTitlePinkLine" />My Skills</div>
                <div className="SkillCardRow">
                    {data.map((value, index) =>{
                        return this.renderSkillsCard(value.icon_source, value.title, value.text)
                    })}
                </div>
            </div>
        )
    }

    renderSkillsCard(icon_source, title, text){
        return (

            <div className="SkillsCard" key={title}>

                <img alt="skill card icon" src={icon_source} className="skillsIcon" />
                <div className="SkillTitleContainer">
                    <div className="SkillCardTitle"><div className="PinkLine" />{title}</div>
                </div>
                <div className="SkillCardText">{text}</div>
            </div>
        )
    }
}

export default SkillsComponent