import React, { Component } from 'react'
import './ArticleBannerStyle.css';

class ArticleBanner extends Component {
    render() {
        const {mainTitle, subTitle} = this.props;
        return (
            <div className="ArticleHeaderSection">
                <div className="Banner_Container">
                    <div className="BlogBannerV3Fog" />

                    <div className="ArticleBannerText">
                        <h1>{mainTitle}</h1>
                        <h2>{subTitle}</h2>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArticleBanner