import {mockArticleData} from "./_DATA_MOCK"
import {api_url, article_api} from './DataConfig'

const ADD_ARTICLE_DATA = "ADD_ARTICLE_DATA"
const REMOVE_ARTICLE_DATA = "REMOVE_ARTICLE_DATA"

// creates the action item that feeds into redux to add blog blurbs
function createAddArticleDataAction (articleData) {
    return {
        type: ADD_ARTICLE_DATA,
        articleData,
    }
}
export function createRemoveArticleDataAction (articleIdToRemove) {
    return {
        type: REMOVE_ARTICLE_DATA,
        articleIdToRemove: articleIdToRemove,
    }
}

export function fetchMockArticleData(articleId){
    return fetch('http://jsonplaceholder.typicode.com/users')
        .then(res=>res.json())
        .then((data)=>{
            data = mockArticleData["data"][articleId];
            data['articleExists'] = true;
            return data
        })
}
export function fetchArticleDataFromApi(articleId){
    let url = api_url+article_api+"/"+articleId;
    let sentData={
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':api_url+'*'
      }
    };
    return fetch(url, sentData)
        .then(res=>res.json())
        .then((data)=>{
            return data
        }).catch((error) => {
          return {
            "articleId":articleId,
            "apiError": true,
            "errorMesssage": "Could not connect to server..."
          }
        })
}

export function fetchArticleData(articleId){
    let fetchingFunction = fetchMockArticleData;
    if (api_url !== ""){
        fetchingFunction = fetchArticleDataFromApi
    }
    return (dispatch) => {
        fetchingFunction(articleId)
        .then(data=>createAddArticleDataAction(data))
        .then((data)=>{
            dispatch(data)
        })
    }
}



export function articleData(state = {loadedArticles: {}}, action){
  switch(action.type) {
    case ADD_ARTICLE_DATA :
        let newLoadedArticles = state.loadedArticles;
        newLoadedArticles[action.articleData.articleId] = action.articleData;
        return {
            ...state,
            loadedArticles: newLoadedArticles
        };
    case REMOVE_ARTICLE_DATA :
        let loadedArticles = state.loadedArticles;
        delete loadedArticles[action.articleIdToRemove];
        return {
            ...state,
            loadedArticles: loadedArticles
        };
    default :
      return state
  }
}