import React, { Component } from 'react'
import './AboutMeBackgroundStyles.css';

class AboutMeBackgroundComponent extends Component {
    render() {
        return (
            <div className="AboutMeAnimationBackgroundContainer">
                <img alt="LoadingIconImage" className = "AboutMeBackgroundFirstRing" src="/loading_icon_images/loading_icon_pink.png" />
                <img alt="LoadingIconImage" className = "AboutMeBackgroundSecondRing" src="/loading_icon_images/loading_icon_teal.png" />
                <img alt="LoadingIconImage" className = "AboutMeBackgroundThirdRing" src="/loading_icon_images/loading_icon_orange.png" />
            </div>
        )
    }
}

export default AboutMeBackgroundComponent