import React, { Component } from 'react'
import './BlogBannerStyle.css';

/*
ok, top picks, 4 only
img 1, background_v1
img 2, background_v1
img 8, background_v1
img 9, bakcground_v1
img 12, background_v1
*/

class BlogBanner extends Component {
    render() {
        return (
            <div className="HeaderSection">
                <div className="Blog_Banner_Container">
                    <div className="BlogBannerV3Fog" />
                    <div className="BlogBannerText">
                        <h1>Blog!</h1>
                        <h2>Learn At Your Own Risk!</h2>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogBanner