export let mockBlogBlurbs = {
    "data":[
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"How to get gym-doom running in python3.7",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"the-first-blog",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"The next blog",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"The-next-blog",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 2!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"3",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 3!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"4",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 4!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"5",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 5!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/background_v1.png",
            blogArticleId:"6",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title one!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"7",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 1!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"8",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 2!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"9",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 3!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"10",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 4!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"11",
        },
        {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Blog Title 5!",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            blogArticleId:"12",
        },
    ]
}

let s = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacinia quis vel eros donec ac odio tempor orci dapibus. Purus in massa tempor nec. Donec enim diam vulputate ut pharetra sit. Ut enim blandit volutpat maecenas volutpat blandit. Nulla facilisi morbi tempus iaculis urna id volutpat lacus laoreet. Vel orci porta non pulvinar. Sed id semper risus in hendrerit gravida rutrum quisque. Mi quis hendrerit dolor magna eget. Tortor at auctor urna nunc id cursus metus aliquam. Fermentum dui faucibus in ornare quam viverra orci. Justo laoreet sit amet cursus sit amet dictum. Proin libero nunc consequat interdum varius sit. Erat pellentesque adipiscing commodo elit at imperdiet. Integer malesuada nunc vel risus commodo viverra maecenas accumsan lacus. At consectetur lorem donec massa sapien faucibus et molestie ac.";
var middle = Math.floor(s.length / 2);
var s1 = s.substr(0, middle);
var s2 = s.substr(middle + 1);
export let mockArticleData = {
    "data":{
        "the-first-blog": {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"How to get gym-doom running in python3.7",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            id:"the-first-blog",
            articleContent: [
            {content_type:"main_header", content: "chapter4"},
            {content_type:"sub_header", content: "chapter4"},
            {content_type:"anchor", content: {anchorId:'Anchor 0', anchorText:"First Anchor Here!"}},
            {content_type:"sub_header", content: "Anchor0"},

            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {content_type:"anchor", content: {anchorId:'Anchor 1', anchorText:"First Anchor Here!"}},
            {content_type:"sub_header", content: "Anchor1"},
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {
                content_type: "full_image",
                content: {imageUrl: "/blogImage.png", comment:"Comment Time!"}
            },
            {content_type:"anchor", content: {anchorId:'Anchor 2', anchorText:"First Anchor Here!"}},
            {content_type:"sub_header", content: "Anchor2"},
            {
                content_type: "right_float_image",
                content: {imageUrl: "/blogImage.png", comment:"Comment Time!"}
            },
            {
                content_type: "left_float_image",
                content: {imageUrl: "/blogImage.png", comment:"Comment Time!"}
            },
            {
                content_type: "youtube_video",
                content: "https://www.youtube.com/embed/tyxo6mfSqWo"
            },
            {content_type:"anchor", content: {anchorId:'Anchor 3', anchorText:"First Anchor Here!"}},
            {content_type:"sub_header", content: "Anchor3"},
            {
                content_type:"numbered_list",
                content: ["item 1", "item 2", "cheese"]
            },
            {
                content_type:"unordered_list",
                content:["item 1", "item b", "CANADA"]
            },
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {content_type:"anchor", content: {anchorId:'Anchor 4', anchorText:"First Anchor Here!4"}},
            {content_type:"sub_header", content: "Anchor4"},
            {
                content_type: "code_block",
                content: {
    language: "python",
    title: "Code_Sample.py",
    codeString: `while True:
    print("Hello World!")`
                        }
            },
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            },
        ]

        },
        "The-next-blog": {
            publishDay:"11",
            publishMonth:"Aug",
            publishYear:"2011",
            blogTitle:"Look....I just needed another mock article to test with....",
            blogBlurb:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...",
            blogImage:"/blogImage.png",
            id:"The-next-blog",
            articleContent: [
            {content_type:"main_header", content: "chapter4"},
            {content_type:"sub_header", content: "chapter4"},
            {content_type:"sub_header", content: "Anchor0"},

            {
                content_type: "paragraph",
                content: [
                    {textType:"plain", text:s1},
                    {textType:"bold", text:"BOLD CONTENT"},
                    {textType:"plain", text:s2}
                ]
            }
            ]
        }
    }
}

export let mockProjectBlurbs = {
    "data":[{
        category_title:"Pinned Projects",
        category_blurb:"Here are some interesting projects that I'm particularly proud of",
        projectBlurbs: [
            {title:"This is going to be a long title to test how realistically long I should make it.", description:"This is a short description of the content", article_id:"the-first-blog", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry2", description:"This is a short description of the content", article_id:"the-first-blog", image_url:"/ProjectImages/2.gif"},
            {title:"Test Entry3", description:"This is a short description of the content", article_id:"The-next-blog", image_url:"/ProjectImages/3.jpg"},
            {title:"Test Entry3", description:"This is a short description of the content", article_id:"the-first-blog13", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry4", description:"This is a short description of the content", article_id:"the-first-blog14", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry5", description:"This is a short description of the content", article_id:"the-first-blog15", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry6", description:"This is a short description of the content", article_id:"the-first-blog16", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry7", description:"This is a short description of the content", article_id:"the-first-blog17", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry8", description:"This is a short description of the content", article_id:"the-first-blog18", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry9", description:"This is a short description of the content", article_id:"the-first-blog19", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry10", description:"This is a short description of the content", article_id:"the-first-blog110", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
        ]
    },
    {
        category_title:"Pinned Projects2",
        category_blurb:"Here are some interesting projects that I'm particularly proud of22",
        projectBlurbs: [
            {title:"This is going to be a long title to test how realistically long I should make it.", description:"This is a short description of the content", article_id:"the-first-blog11", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry2", description:"This is a short description of the content", article_id:"the-first-blog2", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry3", description:"This is a short description of the content", article_id:"the-first-blog3", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry4", description:"This is a short description of the content", article_id:"the-first-blog4", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry5", description:"This is a short description of the content", article_id:"the-first-blog5", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry6", description:"This is a short description of the content", article_id:"the-first-blog6", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry7", description:"This is a short description of the content", article_id:"the-first-blog7", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry8", description:"This is a short description of the content", article_id:"the-first-blog8", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry9", description:"This is a short description of the content", article_id:"the-first-blog9", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry10", description:"This is a short description of the content", article_id:"the-first-blog10", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
        ]
    },
    {
        category_title:"Machine Learning",
        category_blurb:"idk what to put here, but this needs to be a length test to see how long I should make descriptiosn before I cut it off. ",
        projectBlurbs: [
            {title:"This is going to be a long title to test how realistically long I should make it.", description:"This is a short description of the content", article_id:"the-first-blog", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry244", description:"This is a short description of the content", article_id:"the-first-blo66656g", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
        ]
    },
    {
        category_title:"Machine Learning2",
        category_blurb:"idk what to put here, but this needs to be a length test to see how long I should make descriptiosn before I cut it off. ",
        projectBlurbs: [
            {title:"This is going to be a long title to test how realistically long I should make it.", description:"This is a short description of the content", article_id:"the-first-blog", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry244", description:"This is a short description of the content", article_id:"the-first-blog123", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
        ]
    },
    {
        category_title:"Machine Learning3",
        category_blurb:"idk what to put here, but this needs to be a length test to see how long I should make descriptiosn before I cut it off. ",
        projectBlurbs: [
            {title:"This is going to be a long title to test how realistically long I should make it.", description:"This is a short description of the content", article_id:"the-first-blog", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
            {title:"Test Entry244", description:"This is a short description of the content", article_id:"the-first-blog5467", image_url:"https://www.w3schools.com/howto/img_avatar.png"},
        ]
    }
]}