import React, { Component } from 'react'
import "./ProjectCardStyles.css"
import "./ProjectCardStylesResponsiveness.css"
import { NavLink } from 'react-router-dom';


class ProjectCategorySliderComponent extends Component {
    renderProjectCards(project_cards, starting_index){
        let fades = [
            ["PinkCard", "PinkFade"],
            ["PurpleCard", "PurpleFade"],
            ["TealCard", "TealFade"],
        ]
        let scroll_colors = [
            "PurpleScrollBar",
            "PinkScrollBar",
            "TealScrollBar"
        ]
        let scroll_color = scroll_colors[starting_index%scroll_colors.length]

        return(
            <div className={"ProjectContentArea "+scroll_color}>
                {project_cards.map((project_card, index)=>{
                    let fade = fades[(index+starting_index)%fades.length]
                    let card_color = fade[0];
                    let card_fade = fade[1];
                    let project_page_link = "/Projects/"+project_card.articleId;
                    return (
                        <NavLink to={project_page_link} className={"ProjectCard "+card_color} key={project_card.articleId+card_color+starting_index} >
                            <img alt="project visual" src={project_card.projectImageUrl} className="ProjectCardImage" />
                            <div className={card_fade}/>
                            <h2 className="ProjectCardText">{project_card.title}</h2>
                        </NavLink>)
                })}
                <div className="ProjectCardSpacer" />
            </div>
        )
    }

    renderProjectCategory(project_category, index){
        let fades = [
            "TealToPinkFadeBackground",
            "PinkToPurpleFadeBackground",
            "PurpleToTealFadeBackground",
        ]
        let fade = fades[index%fades.length]
        const{ category_blurb, category_title, projectBlurbs} = project_category;
        return(
            <div className="ProjectCategorySpaceWrapper">
                <div className="ProjectCategoryWrapper">
                    <div className="ProjectCategoryHeader">
                        <h1 className="ProjectCategoryHeaderText">{category_title}</h1>
                        <div className="ProjectCategoryDividerLine"><hr/></div>
                    </div>
                    <div className="ProjectDescAndCardWrapper ">
                        <div className={"ProjectCategoryDesc "+fade}>
                            <p className="ProjectCategoryDescText">{category_blurb}</p>
                        </div>
                        {this.renderProjectCards(projectBlurbs, index)}
                    </div>

                </div>
            </div>
        )
    }

    render() {
        return this.renderProjectCategory(this.props.project_category, this.props.fade_index)
    }
}

export default ProjectCategorySliderComponent