import { combineReducers } from 'redux'
import {blogBlurbs, fetchInitialBlogBlurbData} from './BlogBlurbData'
import {articleData} from './ArticleData'
import {projectBlurbs, fetchInitialProjectBlurbData} from './ProjectBlurbData'

export function loadInitialData(){
    //TODO: check if local or on the net, if not local, then do api request,
    return fetchInitialBlogBlurbData()
}
export function loadProjectBlurbData(){
    return fetchInitialProjectBlurbData()
}

export default combineReducers({
    blogBlurbs,
    articleData,
    projectBlurbs
})
