import React, { Component } from 'react'
import "./FooterComponentStyles.css"
import { NavLink } from 'react-router-dom';
import SocialMediaIconContainerComponent from "./NavigationComponents/SocialMediaIconContainerComponent"


class FooterComponent extends Component {
    render() {
        return (
            <div className="FooterComponentContainer">
                <div className="FooterContent">
                    <div className="FooterIcons"><SocialMediaIconContainerComponent /></div>
                    <div className="FooterText">
                        <div>Powered by duct-tape, sheer force of will and a wish.</div>
                        <div>For inquiries please<NavLink to='/ContactMe' className="ContactMeButton">Contact Me Here</NavLink></div>
                    </div>
                    <div className="MobileFooterText">
                        <NavLink to='/ContactMe' className="ContactMeButton">Contact Me Here</NavLink>
                    </div>
                    <div className="FooterCopyrightText">©Copyright 2021 by Tyler Poff</div>
                </div>
            </div>
        )
    }
}

export default FooterComponent