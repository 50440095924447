import React, { Component } from 'react'
import "./EducationComponentStyles.css"


class EducationComponent extends Component {


    render_text(){
        return(
        <div>
            <div className="EducationComponentTitle OrangeText"><div className="SkillsTitlePinkLine" />Education</div>
                <div className="EducationText">
                    <div>I have always loved computers and learning how things worked, I was able to foster this curiosity by attending the University of Tennessee!</div>


                    <ul className="EducationStats">
                        <li>
                            <div className="EducationLi">School: </div>University of Tennessee
                        </li>
                        <li>
                            <div className="EducationLi">Degree: </div>B.S. Computer Science
                        </li>
                        <li>
                            <div className="EducationLi">GPA: </div>3.23
                        </li>
                        <li>
                            <div className="EducationLi">Graduated: </div>Spring 2017
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    renderFadingImages(){
        return (
            <div className="fadingImagesContainer">
                <img alt="utk 1"
                     className="educationBackgroundImages firstEducationImage educationalBackgroundImage"
                     src="/education_background_images/utk_logo_v1.png" />
                <img alt="utk 2"
                     className="educationBackgroundImages secondEducationImage educationalBackgroundImage"
                     src="/education_background_images/utk_logo_v2.png" />
                <img alt="utk 3"
                     className="educationBackgroundImages thirdEducationImage educationalBackgroundImage"
                     src="/education_background_images/utk_logo_v3.png" />
                <img alt="utk 4"
                     className="educationBackgroundImages fourthEducationImage educationalBackgroundImage"
                     src="/education_background_images/utk_logo_v4.png" />
                <img alt="utk 25"
                     className="educationBackgroundImages fithEducationImage educationalBackgroundImage"
                     src="/education_background_images/utk_logo_v5.png" />
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className="EducationComponentContainer">
                    {this.renderFadingImages()}
                    {this.render_text()}
                </div>
            </div>
        )
    }
}

export default EducationComponent