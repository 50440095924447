import React, { Component } from 'react'
import './LoadingIconStyle.css';

class LoadingIconComponent extends Component {
    render() {
        return (
            <div className="LoadingIconContainer">
                <img alt="LoadingIconImage" className = "LoadingIconFirstRing" src="/loading_icon_images/loading_icon_pink.png" />
                <img alt="LoadingIconImage" className = "LoadingIconSecondRing" src="/loading_icon_images/loading_icon_teal.png" />
                <img alt="LoadingIconImage" className = "LoadingIconThirdRing" src="/loading_icon_images/loading_icon_orange.png" />
                <img alt="LoadingIconImage" className = "LoadingIconText" src="/loading_icon_images/loading_icon_ring_text.png" />
            </div>
        )
    }
}

export default LoadingIconComponent