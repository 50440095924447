import React, { Component } from 'react'
import BurgerIconButton from "./BurgerIconButton"
import SocialMediaIconContainerComponent from "./SocialMediaIconContainerComponent"
import NavigationLinksContainerComponent from "./NavigationLinksContainerComponent"
import './NavigationComponentStyles.css';
import { NavLink } from 'react-router-dom';

class NavigationComponent extends Component {
    constructor(props){
        super(props)
        this.state = {"burgerMenuOpen": false}
    }
    burgerIconCallback = (button_pressed)=>{
        this.setState({"burgerMenuOpen":button_pressed})
    }

    handleBackgroundClicked = ()=>{
        this.setState({burgerMenuOpen: false})
    }

    render() {
        const { burgerMenuOpen} = this.state
        return (
            <div className="NavigationWrapper">
                <div className={"MenuPageFade "+(this.state.burgerMenuOpen ? "MenuPageFadeReveal" : "")} onClick={this.handleBackgroundClicked}/>
                <div className="NavigationContentSpacer"></div>
                <div className={"NavigationDrawer "+(burgerMenuOpen ? "NavigationDrawer_Open" : "")}>
                    <div className="NavigationDrawerContent">
                        <NavLink to='/'><img alt="TylerPoff Logo" className="LogoImg" src="/logo/logo_name_black.png" /></NavLink>

                        <NavigationLinksContainerComponent />

                        <div  className="socialMediaWrapper"><SocialMediaIconContainerComponent /></div>
                    </div>

                    <div className="menuBurgerButtonContainer">
                        <BurgerIconButton callbackFunction={this.burgerIconCallback} burgerMenuOpen={this.state.burgerMenuOpen}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavigationComponent