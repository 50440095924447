import {mockProjectBlurbs} from "./_DATA_MOCK"
import { project_blurb_api, api_url} from './DataConfig'

const ADD_PROJECT_BLURBS = "ADD_PROJECT_BLURBS"

// creates the action item that feeds into redux to add blog blurbs
function createAddProjectBlurbsAction (projectBlurbs) {
    return {
        type: ADD_PROJECT_BLURBS,
        projectBlurbs,
    }
}


// the then gets used as the resolution function, so whatever gets passed into resolutionFunction
// is used in the then argument, so for the promise.all it gets a list, since each resolution is passing in
// it's own item, 1 element for each promise.
export function fetchMockProjectBlurbData(){
    return fetch('http://jsonplaceholder.typicode.com/users')
        .then(res=>res.json())
        .then((data)=>{
            data = mockProjectBlurbs["data"];
            data = {
                projectBlurbs: data,
            }
            return data
        })
}

function fetchProjectBlurbDataFromApi(){
    let url = api_url+project_blurb_api;
    let sentData={
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':api_url+'*'
      }
    };
    return fetch(url, sentData)
        .then(res=>res.json())
        .then((data)=>{
            return data
        }).catch((error) => {
          return {
            apiError:true,
            projectBlurbs:[],
          }
        })
}

export function fetchProjectBlurbData(){
    if (api_url !== ""){
        return fetchProjectBlurbDataFromApi()
    }
    return fetchMockProjectBlurbData()
}

export function fetchNextProjectBlurbBatch(starting_index){
    return (dispatch) => {
        fetchProjectBlurbData()
        .then(data=>createAddProjectBlurbsAction(data))
        .then((data)=>{
            dispatch(data)
        })
    }
}

export function fetchInitialProjectBlurbData(){
    return fetchNextProjectBlurbBatch(0)
}

export function projectBlurbs(state = {projectBlurbs: []}, action){
  switch(action.type) {
    case ADD_PROJECT_BLURBS :
        return {
            ...state,
            apiError: action.projectBlurbs["apiError"],
            projectBlurbs: [
                ...state.projectBlurbs,
                ...action.projectBlurbs["projectBlurbs"],

            ]
        };
    default :
      return state
  }
}