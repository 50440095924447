import React, { Component } from 'react'
import "./EmploymentHistoryComponentStyles.css"
import StarBackgroundComponent from "./StarBackgroundEffect/StarBackgroundComponent"

class EmploymentHistoryComponent extends Component {
    renderTimelineEntryMainContentRight(entry){
        let animationDelay = Math.floor(Math.random() * Math.floor(5));
        let fullIconClassName="timelineCircleIcon pulseDotDelay"+animationDelay
        return (
            <div className="timelineEntry">
                <div className="timelineLeftEntrySide timelineDate">
                    {entry.entryDate ?
                        <div className="timelineContentWrapper">
                            <div className="timelineContent">
                            <h2>{entry.entryDate}</h2>
                        </div>
                    </div> : null}
                    <div className={fullIconClassName}/>
                </div>
                <div className="timelineRightEntrySide timelineMainContent">
                    {entry.entryTitle && entry.entryContent ?
                        <div className="timelineContentWrapper">
                            <div className="timelineContent">
                              <h2>{entry.entryTitle}</h2>
                              <p>{entry.entryContent}</p>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        )
    }
    renderTimelineEntryMainContentLeft(entry){
        let animationDelay = Math.floor(Math.random() * Math.floor(5));
        let fullIconClassName="timelineCircleIcon pulseDotDelay"+animationDelay
        return (
            <div className="timelineEntry">
                <div className="timelineLeftEntrySide timelineMainContent">
                    {entry.entryTitle && entry.entryContent ?
                        <div className="timelineContentWrapper">
                            <div className="timelineContent">
                              <h2>{entry.entryTitle}</h2>
                              <p>{entry.entryContent}</p>
                            </div>
                        </div>
                    : null}
                    <div className={fullIconClassName}/>
                </div>
                <div className="timelineRightEntrySide timelineDate">
                    {entry.entryDate ?
                        <div className="timelineContentWrapper">
                            <div className="timelineContent">
                                <h2>{entry.entryDate}</h2>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        )
    }
    renderTimelineEntry(entry){
        if (entry.contentSide === "left"){
            return this.renderTimelineEntryMainContentLeft(entry)
        }
        else{
            return this.renderTimelineEntryMainContentRight(entry);
        }
    }
    render() {
        let data = [
            {
                entryDate: "2017",
                entryTitle: "Graduated From the University of Tennessee",
                entryContent: "Graduated during the Spring of 2017 with a Bacholors in Computer Science.",
                contentSide:"right",
            },
            {
                entryTitle: "AT&T",
                entryContent: "Accepted position at AT&T as a part of their Technology Development Program, a program designed to bring in new graduates.",
                contentSide:"right",
            },
            {
                entryTitle: "Full Stack Developer",
                entryContent: "Started work as a full stack developer creating custom dashboard front ends and back ends that connect to external APIs to pull and update data. Primarily used to assist with financial tracking and project progress.",
                contentSide:"right",
            },
            {
                entryDate: "2018",
                entryTitle: "Data Science, Software Developer",
                entryContent: "Worked as a member of a Big Data and Data Science team. Main focus was developing APIs and machine learning solutions for a variety of tasks such as optical character recognition. Handled various aspects of the API development as well as creating pipelines for data manipulation and fine tuning models for OCR. ",
                contentSide:"left",
            },
            {
                entryDate: "2019",
                entryTitle: "Data Science, Software Developer",
                entryContent: "Worked on a Data Science team to develop a variety of machine learning based solutions for various problems. These ranged from reinforcement learning POCs for 5G solutions, optimization for 3G decommissioning efforts, tech routing optimization and enhanced OCR capabilities.",
                contentSide:"Right",
            },
            {
                entryDate: "2020",
                entryTitle: "Data Science, Software Developer",
                entryContent: "Accepted Position as a Big Data Software Engineer with an emphasis on utilizing ai for various problems, such as customer churn, utilizing Graph Databases for data analysis and pattern detection, and exploring new technologies for potential applications for future projects. ",
                contentSide:"left",
            },
        ]
        return (
            <div className="EmploymentHistoryComponentContainer">
                <div className="EmployementHistoryBackground"><StarBackgroundComponent /></div>
                <div className = "EmployementHistoryContent">
                    <div className="ExperienceTitle"><div className="SkillsTitlePinkLine" />Work Experience</div>

                    <div className="timeline">
                        <div className="timelineFullSize">
                            {data.map((value, index)=>{
                                return (<div key={"timeline"+index}>{this.renderTimelineEntry(value)}</div>)
                            })}
                        </div>
                        <div className="timelineMobileSize">
                            {data.map((value, index)=>{
                                let new_value =
                                {
                                    entryDate: value['entryDate'],
                                    entryTitle: value['entryTitle'],
                                    entryContent: value['entryContent'],
                                    contentSide:"right",
                                }
                                return (<div key={"mobileTimeline"+index}>{this.renderTimelineEntry(new_value)}</div>)
                            })}
                        </div>
                        <div className="timelineSmallMobileSize">
                            {data.map((value, index)=>{
                                let new_value =
                                {
                                    entryTitle: value['entryTitle'],
                                    entryContent: value['entryContent'],
                                    contentSide:"right",
                                }
                                let date_value = {
                                    entryDate: value['entryDate'],
                                    contentSide:"left",
                                }
                                if (value['entryDate']){
                                    return (<div key={"timelineSmallMobile"+index}>
                                                {this.renderTimelineEntry(date_value)}
                                                {this.renderTimelineEntry(new_value)}
                                            </div>);
                                }
                                else{
                                    return (<div key={"timelineSmallMobile"+index}>{this.renderTimelineEntry(new_value)}</div>)
                                }
                            })}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default EmploymentHistoryComponent