import React, { Component } from 'react'
import "./ContactMeComponentPageStyles.css"
import LoadingIconComponent from '../LoadingIconComponent'
import {api_url, contact_me_api} from '../../Data/DataConfig'

class ContactMePageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message_status: 'waiting_input',
            message_name: "",
            message_email: "",
            message: "",
            message_error: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    handleChange(event) {
        const name = event.target.name
       this.setState({[name]: event.target.value});
   }

    send_message_to_api(){
        let url = api_url+contact_me_api
        let message_body = {
            email: this.state.message_email,
            message: this.state.message,
            name: this.state.message_name
        }
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
              mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':api_url+'*' },
            body: JSON.stringify(message_body),
        };
        console.log("options:")
        console.log(requestOptions)
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.handle_api_success()
            })
            .catch((error) => {
              this.handle_api_failure();
              console.log("failure");
              console.log(error)
            });
    }
    handle_api_success(){
        this.setState({
            message_status: "sent"
        })

    }
    handle_api_failure(){
        this.setState({
            message_status: "error_sending",

        })

    }
    validate_email(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    handleSubmit() {
        if (this.state.message_name === ""){
            this.setState({
                message_error: "Name field cannot be blank",
            })
        }
        else if (this.state.message_email === ""){
            this.setState({
                message_error: "Email field cannot be blank",
            })
        }
        else if (!this.validate_email(this.state.message_email)){
            this.setState({
                message_error: "Email entered does not appear to be a valid email",
            })
        }
        else if (this.state.message === ""){
            this.setState({
                message_error: "Message field cannot be blank",
            })
        }
        else{
            this.setState({
                message_status: "pending_sent",
                message_error: "",
            })
            this.send_message_to_api();
        }
    }

    render_contact_me_form(){
        return (
                <form onSubmit={this.handleSubmit} className="ContactMeForm">
                    {this.state.message_status === 'error_sending' &&
                        <div className="ContactMeFormErrorText"><span>Error!</span> Could not reach server to send message...Please try again later</div>}
                    {this.state.message_error !== '' &&
                        <div className="ContactMeFormErrorText"><span>Error!</span> {this.state.message_error}</div>}
                    <label className="ContactMeFormElement">
                        <div className="ContactMeFormText">Name:</div>
                        <input type="text" value={this.state.message_name} onChange={this.handleChange} name="message_name" autoComplete="off"/>
                    </label>
                    <label className="ContactMeFormElement">
                        <div className="ContactMeFormText">Email:</div>
                        <input type="text" value={this.state.message_email} onChange={this.handleChange} name="message_email" autoComplete="off"/>
                    </label>
                    <label  className="ContactMeFormElement">
                        <div className="ContactMeFormText">Message:</div>
                        <textarea className="ContactMeMessage" maxLength="500" placeholder="Message Goes Here!" autoComplete="off" name="message" value={this.state.message} onChange={this.handleChange}/>
                    </label>
                    <div onClick={this.handleSubmit} className="ContactMeSubmitButton">Send Message!</div>
                </form>
        )
    }

    render_loading(){
        return(
            <div className="SendingMessagePendingContainer">
                <div className="ContactMeLoading"><LoadingIconComponent /></div>
                <div className="SendingMessageText">Sending your message, Please Wait...</div>
            </div>
        )
    }

    render_message_sent(){
        return (
            <div className="SendingMessagePendingContainer">
                <div className="SendingMessageText">Message Sent Successfully! </div>
            </div>
        )
    }

    render_form(){
        if (this.state.message_status === "sent")
            return this.render_message_sent()
        if (this.state.message_status === "pending_sent")
            return this.render_loading()
        return this.render_contact_me_form()
    }

    render() {
        return (
            <div className="ContactMeComponentContainer ContactMePageWrapper">

                <div className="ContactMeTitle"><div className="ContactMeLine" />Contact Me</div>
                <div className="ContactMeText">
                    <div>Questions? Comments? Suggestions?</div>
                    <div className="">Send me a message, and I'll get back to you!</div>
                </div>
                {this.render_form()}
            </div>
        )
    }
}

export default ContactMePageComponent