import React, { Component } from 'react'
import './ProjectPage.css';
import { connect } from 'react-redux'
import LoadingIconComponent from '../LoadingIconComponent'
import ProjectCategorySliderComponent from './ProjectCategorySliderComponent'

class ProjectPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = "Tyler Poff Projects"
    }
    componentWillUnmount(){
    }

    renderBanner(){
        return (
            <div className="ProjectHeaderSection">
                <div className="ProjectBanner">
                    <div className="ProjectBannerFog" />
                    <div className="ProjectBannerText">
                        <h1>Projects!</h1>
                        <h2>Behold! My Stuff!</h2>
                    </div>
                </div>
            </div>)
    }

    renderProjectPageContent(){
        let project_categories = this.props.projectBlurbs.projectBlurbs;
        if (this.props.projectBlurbs.apiError === true){
            return (
                <div className="ProjectPageErrorWrapper">
                    <div className="ContactMeFormErrorText"><span>Error!</span> Could not load blog data... please try again later</div>
                </div>
                )

        }
        if (project_categories.length === 0){
            return this.renderLoadingIcon()
        }
        return(
            <div>
                {
                    project_categories.map((project_category, i)=>(
                        <div  key={project_category.category_title}><ProjectCategorySliderComponent project_category={project_category} fade_index={i}/> </div>
                    ))
                }
            </div>
        )
    }

    renderLoadingIcon(){
        return (
            <div>

                <h3>Loading Project Data</h3>
                <div className="loadingIconContainer">
                    <LoadingIconComponent />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="ProjectPageWrapper">
                {
                    this.renderBanner()
                }
                {
                    this.renderProjectPageContent()
                }
            </div>
        )
    }


}


function mapStateToProps ({ projectBlurbs }) {
  return {
    projectBlurbs
  }
}

export default connect(mapStateToProps)(ProjectPage);